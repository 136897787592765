import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import api from "../../../services/api";
import CustomDatePicker from "../../../components/CustomDatePicker";
import blueLogo from "../../../common/assets/blue-logo.svg";

interface Field {
  id: number;
  name: string;
  title: string;
  field_type: string;
  is_required: boolean;
  placeholder?: string;
  step: number;
  field_needed?: string;
  field_needed_value?: string;
  choices?: {
    choices:
      | string[]
      | Array<{
          id: number;
          title: string;
          price: number;
        }>;
  };
}

interface SponsorInfo {
  firstName: string;
  lastName: string;
  opticianName: string;
}

const PublicSectorForm = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      phone: "",
    } as Record<string, any>,
  });
  const [step, setStep] = useState(1);     
  const [fields, setFields] = useState<Field[]>([]);
  const [totalSteps, setTotalSteps] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [sponsorInfo, setSponsorInfo] = useState<SponsorInfo | null>(null);
  const [isSuccess, setIsSuccess] = useState(false);
  
  const currentStepFields = fields.filter((field) => field.step === step);

  // Si pas de token, afficher un message d'erreur
  React.useEffect(() => {
    if (!token) {
      setError("Ce lien n'est plus valide");
    }
  }, [token]);

  React.useEffect(() => {
    const fetchFields = async () => {
      try {
        const response = await api.get(`/fields?sector=OPTIQUE`);
        const sortedFields = sortFieldsByDependency(response.data);
        setFields(sortedFields);
        setTotalSteps(Math.max(...sortedFields.map((f: Field) => f.step)));
      } catch (error) {
        console.error(error);
        setError("Ce lien n'est plus valide");
      }
    };
    if (token) {
      fetchFields();
    }
  }, [token]);

  useEffect(() => {
    const fetchSponsorInfo = async () => {
      if (!token) return;

      try {
        const response = await api.get(`/public-user/${token}`);
        setSponsorInfo(response.data);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des informations du parrain:",
          error
        );
        setError("Ce lien n'est plus valide");
      }
    };

    fetchSponsorInfo();
  }, [token]);

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="bg-white p-8 rounded-lg shadow-sm text-center">
          <h1 className="text-2xl font-bold text-gray-800 mb-4">Erreur</h1>
          <p className="text-gray-600 mb-6">{error}</p>
          <button
            onClick={() => navigate("/")}
            className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700"
          >
            Retour à l'accueil
          </button>
        </div>
      </div>
    );
  }

  const sortFieldsByDependency = (fields: Field[]) => {
    const sorted: Field[] = [];
    const visited = new Set();

    const visit = (field: Field) => {
      if (!visited.has(field.name)) {
        visited.add(field.name);
        const dependency = fields.find((f) => f.name === field.field_needed);
        if (dependency) {
          visit(dependency);
        }
        sorted.push(field);
      }
    };

    fields.forEach((field) => visit(field));
    return sorted;
  };

  const validateStep = async () => {
    const currentStepFields = fields.filter((field) => field.step === step);
    let valid = true;
    for (let field of currentStepFields) {
      const value = watch(field.name as any);
      if (field.is_required && !value) {
        valid = false;
        break;
      }
    }
    return valid;
  };

  const handleNext = async () => {
    if (step === 1) {
      handleSubmit(
        () => {
          setStep(step + 1);
        },
        (errors) => {
          console.log("Validation errors:", errors);
        }
      )();
    } else {
      const isValidStep = await validateStep();
      if (isValidStep) {
        setStep(step + 1);
      }
    }
  };

  const handleBack = () => {
    if (step > 1) {
      // Réinitialiser la valeur du champ date de l'étape suivante
      const nextDateField = fields.find(field => field.step === (step + 1) && field.field_type === 'date');
      if (nextDateField) {
        setValue(nextDateField.name, null);
      }
      setStep(step - 1);
    }
  };

  const handlePhoneChange = (
    onChange: (value: string) => void,
    value: string
  ) => {
    // Supprime tous les caractères non numériques
    const numericValue = value.replace(/\D/g, "");
    // Limite à 10 caractères
    const truncatedValue = numericValue.slice(0, 10);
    onChange(truncatedValue);
  };

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      const formattedData = {
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone,
        customFields: fields.reduce((acc: any, field) => {
          if (data[field.name] !== undefined) {
            acc[field.name] = data[field.name];
          }
          return acc;
        }, {}),
      };

      await api.post(`/public-submit/${token}`, formattedData);
      setIsSuccess(true);
    } catch (error) {
      toast.error("Une erreur s'est produite");
    }
    setIsLoading(false);
  };

  if (isSuccess) {
    return (
      <div className="min-h-screen bg-gray-50">
        <div className="px-5 pt-8">
          <div className="flex justify-center mb-6">
            <img 
              src={blueLogo}
              alt="Logo Fideliz" 
              className="h-8"
            />
          </div>
          {sponsorInfo && (
            <div className="bg-white rounded-lg p-4 mb-6 shadow-sm">
              <p className="text-center text-gray-700">
                Recommandé par{" "}
                <span className="font-semibold">
                  {sponsorInfo.firstName} {sponsorInfo.lastName}
                </span>
              </p>
              {sponsorInfo.opticianName && (
                <p className="text-center text-gray-600 text-sm mt-1">
                  Pour la boutique{" "}
                  <span className="font-semibold">
                    {sponsorInfo.opticianName}
                  </span>
                </p>
              )}
            </div>
          )}
          <div className="bg-white p-8 rounded-lg shadow-sm text-center max-w-md mx-auto">
            <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-6">
              <svg className="w-8 h-8 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
            </div>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">Rendez-vous validé !</h2>
            <p className="text-gray-600 mb-3">
              Vous allez recevoir un SMS pour confirmer votre rendez-vous.
            </p>
            <p className="text-gray-600 mb-3">
              Un SMS de rappel vous sera également envoyé la veille de votre rendez-vous.
            </p>
            <p className="text-gray-600">
              A bientôt chez votre opticien !
            </p>
          </div>
        </div>
      </div>
    );
  }

  const renderField = (field: Field) => {
    const dependentFieldValue = watch(field.field_needed);
    if (field.field_needed && dependentFieldValue !== field.field_needed_value) {
      return null;
    }

    let title = field.title;
    if (title === "Le client a-t-il la CMU ou une mutuelle ?") {
      title = "Avez-vous une mutuelle ou la CMU ?";
    } else if (title === "Quel est le nom de la mutuelle ?") {
      title = "Quel est le nom de votre mutuelle ?";
    } else if (title === "Sélectionnez la date de la visite :") {
      title = "Sélectionnez la date de votre visite :";
    }

    switch (field.field_type) {
      case "text":
      case "mail":
        return (
          <Controller
            key={field.id}
            control={control}
            name={field.name}
            rules={{ required: field.is_required }}
            render={({ field: { onChange, value } }) => (
              <div className="mb-4 w-full">
                <label className="font-semibold text-sm mb-1.5 block text-gray-700">
                  {title}
                </label>
                <input
                  type={field.field_type === "mail" ? "email" : "text"}
                  onChange={onChange}
                  value={value || ""}
                  placeholder={field.placeholder}
                  className="w-full px-5 py-3 border border-gray-300 rounded-lg bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            )}
          />
        );

      case "radio":
        return (
          <Controller
            key={field.id}
            control={control}
            name={field.name}
            rules={{ required: field.is_required }}
            render={({ field: { onChange, value } }) => (
              <div className="mb-4 w-full">
                <label className="font-semibold text-sm mb-1.5 block text-gray-700">
                  {title}
                </label>
                <div className="flex flex-wrap gap-4">
                  {field.choices?.choices.map((choice, index) => (
                    <div key={index} className="flex items-center">
                      <button
                        type="button"
                        onClick={() => onChange(choice)}
                        className={`h-6 w-6 rounded border flex items-center justify-center mr-2.5 ${
                          value === choice
                            ? "bg-blue-500 border-blue-500"
                            : "bg-white border-gray-300"
                        }`}
                      >
                        {value === choice && (
                          <div className="w-3.5 h-3.5 rounded-full bg-white" />
                        )}
                      </button>
                      <span className="text-base text-gray-700">{choice}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          />
        );

      case "select":
        return (
          <Controller
            key={field.id}
            control={control}
            name={field.name}
            rules={{ required: field.is_required }}
            render={({ field: { onChange, value } }) => (
              <div className="mb-4 w-full">
                <label className="font-semibold text-sm mb-1.5 block text-gray-700">
                  {title}
                </label>
                <select
                  onChange={onChange}
                  value={value || ""}
                  className="w-full px-5 py-3 border border-gray-300 rounded-lg bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="">
                    {field.placeholder || "Sélectionnez une option"}
                  </option>
                  {field.choices?.choices.map((choice) => (
                    <option key={choice} value={choice}>
                      {choice}
                    </option>
                  ))}
                </select>
              </div>
            )}
          />
        );

      case "message":
        return (
          <Controller
            key={field.id}
            control={control}
            name={field.name}
            rules={{ required: field.is_required }}
            render={({ field: { onChange, value } }) => (
              <div className="mb-4 w-full">
                <label className="font-semibold text-sm mb-1.5 block text-gray-700">
                  {title}
                </label>
                <textarea
                  onChange={onChange}
                  value={value || ""}
                  className="w-full px-5 py-2.5 border border-gray-300 rounded-lg bg-white min-h-[130px] focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            )}
          />
        );

      case "date":
        return (
          <Controller
            key={field.id}
            control={control}
            name={field.name}
            rules={{ required: field.is_required }}
            render={({ field: { onChange, value } }) => (
              <div className="mb-4 w-full">
                <CustomDatePicker
                  field={field}
                  selectedDateTime={value}
                  setSelectedDateTime={(dateTime) => {
                    onChange(dateTime);
                    if (dateTime && step === field.step) {
                      handleNext();
                    }
                  }}
                />
              </div>
            )}
          />
        );

      default:
        return null;
    }
  };

  const renderStep = () => {
    if (step === 1) {
      return (
        <>
          <Controller
            control={control}
            name="lastName"
            rules={{ required: "Le nom est requis" }}
            render={({ field: { onChange, value } }) => (
              <div className="mb-4 w-full">
                <label className="font-semibold text-sm mb-1.5 block text-gray-700">
                  Nom
                </label>
                <input
                  type="text"
                  onChange={onChange}
                  value={value}
                  className="w-full px-5 py-3 border border-gray-300 rounded-lg bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Votre nom"
                />
                {errors.lastName && (
                  <span className="text-red-500 text-sm mt-1">
                    {errors.lastName.message as string}
                  </span>
                )}
              </div>
            )}
          />

          <Controller
            control={control}
            name="firstName"
            rules={{ required: "Le prénom est requis" }}
            render={({ field: { onChange, value } }) => (
              <div className="mb-4 w-full">
                <label className="font-semibold text-sm mb-1.5 block text-gray-700">
                  Prénom
                </label>
                <input
                  type="text"
                  onChange={onChange}
                  value={value}
                  className="w-full px-5 py-3 border border-gray-300 rounded-lg bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Votre prénom"
                />
                {errors.firstName && (
                  <span className="text-red-500 text-sm mt-1">
                    {errors.firstName.message as string}
                  </span>
                )}
              </div>
            )}
          />

          <Controller
            control={control}
            name="phone"
            rules={{
              required: "Le numéro de téléphone est requis",
              pattern: {
                value: /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/,
                message: "Veuillez entrer un numéro de téléphone valide",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <div className="mb-4 w-full">
                <label className="font-semibold text-sm mb-1.5 block text-gray-700">
                  Téléphone
                </label>
                <input
                  type="tel"
                  onChange={(e) => handlePhoneChange(onChange, e.target.value)}
                  value={value}
                  className="w-full px-5 py-3 border border-gray-300 rounded-lg bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Votre numéro de téléphone"
                  maxLength={10}
                />
                {errors.phone && (
                  <span className="text-red-500 text-sm mt-1">
                    {errors.phone.message as string}
                  </span>
                )}
              </div>
            )}
          />
        </>
      );
    }

    const requiredFields = currentStepFields.filter(
      (field) => field.is_required
    );
    const optionalFields = currentStepFields.filter(
      (field) => !field.is_required
    );

    const hasMessageField = currentStepFields.some(field => field.field_type === 'message');

    return (
      <>
        {requiredFields.length > 0 && requiredFields.map(renderField)}
        {hasMessageField ? (
          optionalFields.map(renderField)
        ) : (
          <>
            {optionalFields.length > 0 &&
              optionalFields.some((field) => {
                const dependentFieldValue = watch(field.field_needed);
                return (
                  !field.field_needed ||
                  dependentFieldValue === field.field_needed_value
                );
              }) && (
                <>
                  {requiredFields.length > 0 && (
                    <div className="w-full pt-1.5 border-t border-gray-300">
                      <span className="text-sm text-gray-600 font-semibold mb-2.5 block">
                        Les champs ci-dessous sont optionnels
                      </span>
                    </div>
                  )}
                  {optionalFields.map(renderField)}
                </>
              )}
          </>
        )}
      </>
    );
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className={`px-5 ${currentStepFields?.every(field => !field.is_required) ? 'pt-8' : 'py-8'}`}>
        <div className="flex justify-center mb-6">
          <img 
            src={blueLogo}
            alt="Logo Fideliz" 
            className="h-8"
          />
        </div>
        {sponsorInfo && (
          <div className="bg-white rounded-lg p-4 mb-6 shadow-sm">
            <p className="text-center text-gray-700">
              Recommandé par{" "}
              <span className="font-semibold">
                {sponsorInfo.firstName} {sponsorInfo.lastName}
              </span>
            </p>
            {sponsorInfo.opticianName && (
              <p className="text-center text-gray-600 text-sm mt-1">
                Pour la boutique{" "}
                <span className="font-semibold">
                  {sponsorInfo.opticianName}
                </span>
              </p>
            )}
          </div>
        )}
        <h1 className="text-center font-semibold text-md mt-5 mb-5 text-gray-800">
          Renseignez vos informations personnelles pour prendre rendez-vous chez
          votre opticien
        </h1>
        <div className="mb-4">
          <div className="flex justify-center text-xs text-gray-600 mb-2">
            <span className="font-semibold">Étape {step} / {totalSteps}</span>
          </div>
          <div className="w-full h-2 bg-gray-200 rounded-full">
            <div 
              className="h-full bg-blue-600 rounded-full transition-all duration-300 ease-in-out"
              style={{ width: `${(step / totalSteps) * 100}%` }}
            />
          </div>
        </div>
      </div>
      

      <div className="px-8">
        <form
          onSubmit={handleSubmit(step === totalSteps ? onSubmit : handleNext)}
        >
          <div className="space-y-4">{renderStep()}</div>

          <div className="mt-5 mb-8 flex gap-4">
            {step > 1 && 
             !(fields.find(field => field.step === step)?.field_type === 'date') && 
             !(fields.find(field => field.step === step)?.field_type === 'message') && (
              <button
                type="button"
                onClick={handleBack}
                className="w-full bg-gray-200 text-gray-700 py-4 rounded-lg hover:bg-gray-300"
              >
                Précédent
              </button>
            )}
            {!(fields.find(field => field.step === step)?.field_type === 'date') && (
              <button
                type="submit"
                disabled={isLoading}
                className="w-full bg-blue-600 text-white py-4 rounded-lg hover:bg-blue-700 disabled:bg-gray-400"
              >
                {step === totalSteps ? "Ajouter" : "Suivant"}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default PublicSectorForm;
