import React, { useState, useEffect } from "react";
import {
  format,
  addDays,
  startOfWeek,
  addWeeks,
  subWeeks,
  isBefore,
  isSameDay,
  startOfDay,
  getHours
} from "date-fns";
import { fr } from "date-fns/locale";

interface CustomDatePickerProps {
  selectedDateTime: string | undefined;
  setSelectedDateTime: (dateTime: string | undefined) => void;
  field?: any;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  selectedDateTime,
  setSelectedDateTime,
  field
}) => {
  const [currentWeek, setCurrentWeek] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [openDays, setOpenDays] = useState<Record<string, boolean>>({});
  const [isCurrentWeekValid, setIsCurrentWeekValid] = useState(true);

  const timeSlots = ["Matin", "Après-midi"];

  useEffect(() => {
    checkCurrentWeek();
  }, [currentWeek]);

  const checkCurrentWeek = () => {
    const startOfCurrentWeek = startOfWeek(currentWeek, { weekStartsOn: 1 });
    const daysOfWeek = Array.from({ length: 6 }, (_, index) =>
      addDays(startOfCurrentWeek, index)
    );

    const today = new Date();
    const hasFutureDays = daysOfWeek.some((day) => !isBefore(day, today));
    setIsCurrentWeekValid(hasFutureDays);

    if (!hasFutureDays) {
      setCurrentWeek(addWeeks(currentWeek, 1));
    }
  };

  const handleTimeSelect = (date: Date, timeSlot: string) => {
    const updatedDateTime = startOfDay(new Date(date));
    const formattedDate = format(updatedDateTime, "dd/MM/yyyy", { locale: fr });
    const updatedDateTimeString = `${formattedDate} ${timeSlot}`;

    if (selectedDateTime === updatedDateTimeString) {
      setSelectedTime(null);
      setSelectedDateTime(undefined);
    } else {
      setSelectedTime(timeSlot);
      setSelectedDateTime(updatedDateTimeString);
    }
  };

  const toggleDay = (day: string) => {
    setOpenDays((prev) => ({ ...prev, [day]: !prev[day] }));
  };

  const startOfCurrentWeek = startOfWeek(currentWeek, { weekStartsOn: 1 });
  const daysOfWeek = Array.from({ length: 6 }, (_, index) =>
    addDays(startOfCurrentWeek, index)
  );

  const today = new Date();
  const isCurrentWeek =
    startOfCurrentWeek <= today && today < addWeeks(startOfCurrentWeek, 1);

  const handlePrevWeek = () => {
    if (!isBefore(subWeeks(currentWeek, 1), startOfWeek(today, { weekStartsOn: 1 }))) {
      setCurrentWeek(subWeeks(currentWeek, 1));
    }
  };

  const handleNextWeek = () => {
    setCurrentWeek(addWeeks(currentWeek, 1));
  };

  const capitalizeFirstLetter = (string: string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getTimeRestrictions = (day: Date, timeSlot: string) => {
    const now = new Date();
    const isToday = isSameDay(day, now);
    const currentHour = getHours(now);

    if (isToday) {
      if (timeSlot === "Matin" && currentHour >= 10) return true;
      if (timeSlot === "Après-midi" && currentHour >= 14) return true;
    }
    return false;
  };

  return (
    <div className="w-full">
      {field?.title && (
        <div className="font-semibold text-sm mb-1.5 text-gray-700">{field.title}</div>
      )}

      <div className="flex items-center justify-between w-full mb-4">
        <button
          onClick={handlePrevWeek}
          disabled={isCurrentWeek || !isCurrentWeekValid}
          className={`p-2 bg-gray-200 rounded ${(isCurrentWeek || !isCurrentWeekValid) ? 'opacity-30' : 'hover:bg-gray-300'}`}
        >
          ←
        </button>
        <span className="text-sm font-semibold">
          {capitalizeFirstLetter(format(startOfCurrentWeek, "MMMM yyyy", { locale: fr }))}
        </span>
        <button
          onClick={handleNextWeek}
          className="p-2 bg-gray-200 rounded hover:bg-gray-300"
        >
          →
        </button>
      </div>

      {daysOfWeek.map((day) => {
        const isPastDay = isCurrentWeek && isBefore(day, today);
        if (isPastDay) return null;

        return (
          <div key={day.toISOString()} className="mb-4 w-full">
            <button
              onClick={() => toggleDay(day.toISOString())}
              className="w-full flex justify-between items-center py-2 border-b border-gray-300"
            >
              <span className="text-sm font-semibold">
                {capitalizeFirstLetter(format(day, "EEEE dd", { locale: fr }))}
              </span>
              <span className="text-sm">
                {capitalizeFirstLetter(format(startOfCurrentWeek, "MMMM", { locale: fr }))}
              </span>
            </button>

            {openDays[day.toISOString()] && (
              <div className="mt-2 space-y-1">
                {timeSlots.map((timeSlot) => {
                  const timeDate = startOfDay(new Date(day));
                  const isPast = isBefore(timeDate, today);
                  const isSelected = selectedDateTime === `${format(timeDate, "dd/MM/yyyy", { locale: fr })} ${timeSlot}`;
                  const isRestricted = getTimeRestrictions(day, timeSlot);

                  return (
                    <button
                      key={timeSlot}
                      className={`w-full p-2 rounded ${
                        isSelected
                          ? 'bg-blue-600 text-white'
                          : 'bg-gray-200 text-gray-700'
                      } ${(isPast || isRestricted) ? 'opacity-30' : 'hover:bg-blue-500 hover:text-white'}`}
                      onClick={() => {
                        if (!isPast && !isRestricted) {
                          handleTimeSelect(day, timeSlot);
                        }
                      }}
                      disabled={isPast || isRestricted}
                    >
                      {timeSlot}
                    </button>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default CustomDatePicker; 